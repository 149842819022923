.browser-compatibility {
  background-color: #f5d367;
  font-size: 1.4rem;
  color: #272b3f;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  text-align: center;
  grid-area: warning;

  img {
    margin-left: 1rem;
    width: 2rem;
  }
}
