.modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;

  animation: 200ms ease 0s 1 fadeIn;

  @keyframes fadeIn {
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }

  @keyframes fadeInToTop {
    0% {
      opacity: 0;
      margin-top: 3rem;
    }
    100% {
      opacity: 1;
      margin-top: 0;
    }
  }

  &__window {
    width: 40rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: $inputRadius;
    overflow: hidden;
    background: #272b40;
    font-size: 1.3rem;
    opacity: 0;
    animation: 200ms ease 100ms 1 fadeInToTop;
    animation-fill-mode: forwards;

    &__header {
      background: #24273d;
      color: #b7cbdb;
      margin: 0;
      display: flex;
      align-items: center;

      &__title {
        font-size: 1.2rem;
        flex-grow: 1;
        padding: 0 1.4rem;
      }

      &__close {
        width: 3.5rem;
        height: 3rem;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: transparent;
        background-size: 1rem 1rem;
        border: 0;
        cursor: pointer;
        outline: 0;
        padding: 0;
        background-image: url('../../../img/icons/popup-x.svg');

        &:hover,
        &:focus {
          background-color: #30344c;
        }

        &:focus {
          box-shadow: inset 0px 0px 0px 1px #646781a5;
        }
      }
    }

    &__content {
      padding: 1.6rem 1.4rem;
      display: flex;
      align-items: center;

      &__icon {
        margin-right: 1.5rem;
      }

      &__text {
        color: #c7d8e6;

        p {
          line-height: 1.5;
        }

        a {
          color: #c7d8e6;
          font-weight: bold;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__controls {
      padding: 1rem 1.4rem 1rem;
      display: flex;
      justify-content: flex-end;
      background: #24273d;

      button {
        padding: 0.7rem 1rem;
        font-size: 1.2rem;
        min-width: 6rem;

        &:not(:last-child) {
          margin-right: 0.7rem;
        }
      }
    }
  }
}
