.layer-controls__item__header {
  height: $layerHeight;
  display: flex;
  align-items: center;

  .drag-handle {
    flex-shrink: 0;
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    margin-top: 0.2rem;

    img {
      max-width: 100%;
    }
  }

  .layer-color {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 0.4rem;
    margin-right: 0.5rem;
    margin-top: 0.2rem;
  }

  .layer-icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    margin-top: 0.2rem;

    img {
      width: 1rem;
    }
  }

  .name {
    flex-grow: 1;
    display: flex;
    margin-right: 1rem;
    align-items: flex-start;

    form {
      width: 100%;
      display: flex;

      input {
        font-size: 1.2rem;
        color: #bcc2e0;
        background: transparent !important;
        border: 0;
        padding: 0;
        margin: 0;
        outline: 0;
        width: 100%;
        cursor: context-menu;

        &:focus:not(:read-only) {
          background-color: #4a4f64 !important;
        }
      }
    }
  }

  .layer-toggle {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.2rem;
    margin-right: 0.5rem;
    padding: 0.2rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: #24273d;
    }
  }

  .arrow {
    flex-shrink: 0;
    width: 1rem;
    height: 2rem;
    background-image: url('../../../../img/icons/layer-controls-arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    transition: transform 200ms ease;
    margin-left: 0.5rem;

    &:hover {
      opacity: 0.7;
    }

    &.open {
      transform: rotate(180deg);
    }
  }
}
