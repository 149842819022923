#welcome.popup {
  .popup__content {
    .start-projects {
      display: flex;
      align-items: stretch;
      margin-bottom: 1.5rem;

      .default-options {
        display: flex;
        flex-grow: 1;

        button {
          flex-basis: 50%;
        }

        button:not(:last-child) {
          margin-right: 1rem;
        }
      }

      button {
        background-color: #24273d;
        border: 0.2rem solid #2e3247;
        border-radius: 0.4rem;
        padding: 1rem;
        text-align: center;
        color: #a5b7c8;
        cursor: pointer;
        transition: all 150ms ease;
        display: flex;
        flex-direction: column;
        min-width: 15rem;

        &:hover {
          background-color: #2e3247;
        }

        .img-wrapper {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          img {
            max-width: 100%;
          }
        }

        .label {
          margin-top: 1rem;
          width: 100%;
        }
      }

      .demo-projects {
        margin-left: 1rem;
        padding-left: 1rem;
        border-left: 0.2rem solid #2e3247;

        & > .label {
          color: #a5b7c8;
          font-size: 1.3rem;
          margin-bottom: 1rem;
        }

        .content {
          display: flex;

          button {
            max-width: 16rem;

            &:not(:last-child) {
              margin-right: 1rem;
            }
          }
        }
      }
    }

    .welcome-text {
      display: flex;
      align-items: center;

      .meta {
        flex-grow: 1;

        .title {
          font-weight: bold;
          font-size: 2.7rem;
          margin-bottom: 1rem;
        }

        ul.links {
          list-style: none;
          padding: 0;

          li {
            margin-bottom: 0.5rem;
            display: flex;
            align-items: center;

            span {
              background-color: $accentBlue;
              font-size: 1rem;
              padding: 0.2rem 0.5rem;
              color: white;
              border-radius: 0.4rem;
              margin-right: 0.5rem;
              margin-bottom: 0.5rem;
            }
          }
        }
      }

      .logo {
        img {
          width: 8rem;
        }
      }
    }
  }
}
