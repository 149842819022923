.timeline-layers {
  flex-grow: 1;
  z-index: 1;
  overflow: auto;

  .keyframe-property-group {
    padding-top: $lcGroupHeight;
  }

  .keyframes-line {
    height: 0.2rem;
    background: #4d5472;
    width: 2rem;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-0.1rem);
    z-index: 100;
  }

  .timeline-layer {
    opacity: 0.8;
    width: fit-content;

    .layer {
      height: 2rem;
      background: #6ec56e;
      box-shadow: inset 0px -3px 0px 0px rgba(39, 43, 64, 0.5);
      position: relative;

      .start-edge,
      .end-edge {
        width: 0.3rem;
        height: 100%;
        position: absolute;
        background-color: rgba(39, 43, 64, 0.5);
        cursor: e-resize;
        z-index: 1;
      }

      .start-edge {
        left: 0;
      }

      .end-edge {
        right: 0;
      }

      .shade {
        z-index: 0;
        position: absolute;
        top: 0;
        height: 100%;
        width: 5rem;
        background-size: auto;
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='0.3' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
      }

      .start-shade {
        left: 0;
      }
      .end-shade {
        right: 0;
      }
    }

    &.selected {
      background: rgba(255, 255, 255, 0.1);
      opacity: 1;
    }

    .keyframes {
      @import './keyframe-property-row.scss';
    }
  }
}
