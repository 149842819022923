.btn#casparcg-export {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0.8rem;
  margin-right: 1rem;
  font-size: 1.1rem;
  margin-top: -0.2rem;

  img {
    height: 1.5rem;
    margin-right: 1rem;
  }
}
