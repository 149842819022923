.panel-layout {
  padding: 0.8rem 1rem;
  box-sizing: border-box;
  border-top: 0.1rem solid $panelBorderColor;
  border-left: 0.1rem solid $panelBorderColor;
  overflow: auto;
  display: flex;
  flex-direction: column;

  & > .panel-title {
    display: flex;
    align-items: center;
    margin-bottom: 1.2rem;

    span {
      font-size: 1.1rem;
      text-transform: uppercase;
      color: #bcc2e0;
      flex-grow: 1;
    }

    .info {
      margin-left: 0.7rem;
      width: 1.5rem;
    }
  }

  .panel-subarea {
    background-color: #24273d;
    padding: 1rem;
    border: 1px solid #363a54;
    margin-bottom: 1rem;
  }

  .input-wrapper,
  .layer-setting {
    // &:not(:last-child) {
    margin-bottom: 0.6rem;
    // }

    &__label {
      width: 6rem;
      flex-shrink: 0;
    }
  }
}
