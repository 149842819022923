.editor {
  &__code {
    #action-editor-input {
      background-color: #3d4059;

      .ace_gutter {
        background: #24273d;
      }

      .ace_gutter-active-line {
        background: #1c1f31;
      }
      .ace_active-line {
        background: #24273d;
      }
    }
  }

  &__controls {
    margin-top: 1rem;
    width: 100%;
    text-align: right;

    .btn {
      display: inline-block;
    }

    .btn:not(:first-child) {
      margin-left: 1rem;
    }
  }
}
