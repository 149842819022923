.playback-and-actions-panel {
  z-index: 2;
  grid-area: playback;
  text-align: center;
  padding: 0.5rem;
  background: $darkPanelColor;
  display: flex;
  justify-content: center;

  .separator {
    margin: 0 1rem;
  }
  .separator {
    width: 0.1rem;
    background-color: #595c79;
    margin-right: 0.8rem;
    margin-left: 0.4rem;
  }

  .actions-controls {
    display: flex;

    .btn.action {
      &:not(:last-child) {
        margin-right: 0.4rem;
      }
    }
  }
}
