.btn {
  background-color: #3d4059;
  border-radius: 0.4rem;
  color: white;
  outline: 0;
  border: 0;
  padding: 0.5rem 0.8rem;
  margin: 0;
  font-size: 1.2rem;
  color: #c3c7dd;

  &:hover {
    background-color: #4c4f6d;
    cursor: pointer;
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px #646781a5;
  }

  &.plus,
  &.minus {
    display: flex;
    justify-content: center;
    align-items: center;

    img.icon {
      height: 1.1rem;
    }
  }

  &.large {
    padding: 0.7rem 1.3rem;
  }
}

.icon-btn {
  background-color: #3d4059;
  color: white;
  outline: 0;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  color: #c3c7dd;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  transition: all 100ms ease;
  border-radius: 0.4rem 0.4rem 0.4rem 0.4rem;

  img {
    max-width: 1.5rem;
    max-height: 1.5rem;
    height: 1.5rem;
  }

  &:hover {
    background-color: #4d506b;
  }
}
