.timeline-frame-selector {
  white-space: nowrap;
  padding: 2rem 0;
  padding-top: 0;
  padding-bottom: 0;
  background: #272b40;
  user-select: none;
  flex-shrink: 0;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  &__duration {
    display: flex;
    height: 2rem;
    margin-top: 0rem;

    .start-edge,
    .end-edge {
      width: 0.5rem;
      background: $accentBlue;
      cursor: e-resize;
    }

    .start-edge {
      border-radius: 0.4rem 0 0 0.4rem;
    }

    .end-edge {
      border-radius: 0 0.4rem 0.4rem 0;
    }

    .filler {
      flex-grow: 1;
      background: $accentBlue;
      opacity: 0.5;
      border-left: 0.1rem solid $accentBlue;
      border-right: 0.1rem solid $accentBlue;
      opacity: 0.2;
    }
  }

  &__actions {
    height: 2rem;
    position: relative;
  }

  &__btns {
    display: flex;
    border-radius: 0.5rem;
    width: 100%;

    button {
      height: 2rem;
      flex-shrink: 0;
      flex-grow: 0;
      cursor: default;
      text-align: center;
      border: 0;
      outline: 0;
      padding: 0;
      font-size: 1rem;
      color: #9ca1bb;
      background-color: #32364b;
      position: relative;

      transition: $timelineScaleAnimation;
    }

    .label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
    }

    .separator {
      position: absolute;
      height: 100%;
      width: 0.1rem;
      top: 2rem;
      background: #272b40;
    }
  }

  &__line {
    position: absolute;
    height: 100vh;
    top: 2rem;
    transform: translateX(-50%);
    text-align: center;
    z-index: 9;
    pointer-events: none;
    margin-left: -0.1rem;

    .current-frame {
      text-align: center;
      left: 0;
      background: #0096ff;
      padding-top: 0.3rem;
      border-radius: 0.3rem;
      font-size: 1.1rem;
      box-sizing: border-box;
      pointer-events: visible;
      min-width: 2rem;
    }

    .frame-line {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 0.2rem;
      background: rgba(255, 255, 255, 0.3);
      height: 100%;
    }
  }
}
