.inforow {
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border-radius: 0.4rem;
  margin-bottom: 0.5rem;

  &.error {
    background-color: $redBackground;
    color: white;
  }
  &.warning {
  }

  .icon {
  }
  .message {
  }
}
