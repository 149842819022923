#composition-settings {
  flex-grow: 1;
}

#layer-settings {
  flex-grow: 1;

  .layer-setting {
    display: flex;
    flex-grow: 1;

    &__label {
      font-size: 1.1rem;
      margin-right: 1.5rem;
      color: #bcc2e0;
    }

    &__value {
      font-size: 1.1rem;
      color: #bcc2e0;
    }
  }
}
