.input-error-wrapper {
  & > .error-tooltip {
    position: absolute;
    background-color: $redBackground;
    font-size: 1.1rem;
    padding: 0.5rem 1rem 0.7rem;
    width: 100%;
    box-sizing: border-box;
    z-index: 5;
    border-radius: $inputRadius;
    top: 100%;
    transform: translateY(-0.1rem);

    .triangle {
      background-color: $redBackground;
      width: 1rem;
      height: 1rem;
      position: absolute;
      top: 0;
      transform: translateY(-0.5rem) rotate(45deg);
    }
  }
}
