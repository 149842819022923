.renderer-panel {
  background-color: #161927;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  grid-area: main;
  display: flex;
  flex-direction: column;
  z-index: 0;

  .render-section {
    flex-grow: 1;
    height: 100%;
    z-index: 0;
    overflow: hidden;

    .drag-selector {
      width: 10rem;
      height: 5rem;
      background: #2b79b080;
      border-radius: 0.4rem;
      z-index: 100;
      position: fixed;
      box-shadow: inset 0 0 0 0.3rem $accentBlue;
    }

    &.space-down {
      cursor: grab;
      user-select: none;
    }

    &.moving {
      cursor: grabbing;
      user-select: none;
    }

    .render {
      position: absolute;
      background: white;
      left: 50%;
      top: 50%;
      // transition: transform 100ms ease;

      .element {
        white-space: nowrap;
        position: absolute;
        user-select: none;

        box-sizing: content-box;
        color: red;
      }
    }
  }
}
