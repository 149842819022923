$borderColor: #579cce;
$handlerColor: $accentBlue;

.element-controls {
  position: fixed;
  box-shadow: inset 0 0 0 0.2rem $borderColor;
  box-sizing: border-box;
  z-index: 200;
  pointer-events: none;
  opacity: 0;

  &.hover {
    opacity: 0.5;
  }

  &.selected {
    opacity: 1;
  }

  &__filler {
    position: absolute;
    background: transparent;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .handle {
    pointer-events: fill;
    width: 1rem;
    height: 1rem;
    // border-radius: 50%;
    background: white;
    box-shadow: inset 0 0 0 0.2rem $borderColor;
    position: fixed;
    top: 0;
    left: 0;

    &:hover {
      background: $handlerColor;
    }
  }
}
