.top-content-bottom-controls {
  display: flex;
  flex-direction: column;
  width: 100%;

  .top-content {
    flex-grow: 1;
    padding: 1rem 2rem;
  }

  .bottom-content {
    border-top: 1px solid #30344c;
    padding: 1rem 2rem;
    background-color: #24273d;
  }
}
