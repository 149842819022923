.input-wrapper {
  width: 100%;
  align-items: center;

  &.label-left {
    display: flex;

    & > .label {
      width: 6rem;
    }
  }

  &.label-top {
    & > .label {
      display: block;
      margin-bottom: 0.5rem;
    }
  }

  & > .label {
    font-size: 1.1rem;
    margin-right: 1.5rem;
    color: #bcc2e0;
  }

  & > .icon {
    width: 1.5rem;
    margin-right: 1rem;
  }

  & > .control {
    flex-grow: 1;
    position: relative;

    .dropdown {
      width: 100%;
    }

    .color-holder {
      position: relative;
    }

    .color-pattern {
      cursor: pointer;
      position: relative;
      width: 4rem;
      height: 2.3rem;
      border: 1px solid #363a54;
      border-radius: $inputRadius;
    }

    .number-controls {
      display: flex;
      width: 100%;

      & > input {
        width: 5rem;
        text-align: center;
      }

      button {
        margin-left: 1rem;
        width: 3rem;
      }
    }

    & > input {
      width: 100%;
    }
  }
}
