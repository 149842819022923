.react-contexify {
  border-radius: 0.4rem;
  min-width: 15rem;
  background-color: $darkPanelColor;
  z-index: 150;

  &__item {
    &:hover {
      &__content {
        background-color: $accentBlue;
      }
    }

    &__content {
      font-size: 1.2rem;
      color: #bcc2e0;
      padding: 0.4rem 0.7rem;
    }

    .icon-item {
      display: flex;
      align-items: center;

      &__img {
        padding: 0.5rem;
        background-color: #3d4059;
        margin-left: 0.5rem;
        border-radius: $inputRadius;

        img {
          width: 1.5rem;
        }
      }

      &__label {
        margin-left: 1rem;
      }
    }
  }

  &__submenu-arrow {
    font-size: 1rem;
  }
}
