// GENERAL
// Green
$greenBackground: #51af66;
$darkerGreenBackground: #36aa4f;
$greenHover: #38b152;
$greenColor: #ffffff;
$greenSep: #bcf5c9;

// Red
$redBackground: #d24e54;
$redBackgroundHover: #aa393f;
$onRedTextColor: #ffffff;
$redSep: #fdbdc0;

// Orange
$orangeBackground: #df9a4b;
$orangeHover: #b87b35;
$orangeColor: #ffffff;
$orangeSep: #f3d5b2;

// BUTTONS
$btnDefault: #3d4059;
$btnDefaultColor: #d5e1e9;
$btnDefaultHover: #464861;
$btnDefaultFocus: #646781a5;

// NAV
$mainNav: #24273f;
$mainNavSublist: #1f2135;
$accentBlue: #0096ff;
$accentBlueHover: #087cce;

// UTILITIES
$errorRed: #aa393f;
$errorRedFocus: #c45056;

// CARDS
$yellowCard: #dfc935;
$redCard: #df3535;
