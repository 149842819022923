.action {
  color: #383d52;
  background-color: #eaebf1;
  padding: 0 0.7rem 0.4rem 0.3rem;
  box-shadow: inset 0px -4px 0px 0px $redBackground;
  display: flex;
  align-items: center;
  outline: 0;
  border: 0;

  &:hover {
    background-color: #d7d9e2;
  }

  &:focus {
    box-shadow: inset 0px -4px 0px 0px $redBackgroundHover;
  }

  img {
    width: 1.8rem;
  }

  span {
    font-weight: bold;
    font-size: 0.9rem;
  }
}

.action-flag {
  color: #383d52;
  background-color: #eaebf1;
  box-shadow: inset 0px 4px 0px 0px $redBackground;
  display: flex;
  align-items: center;
  position: absolute;
  height: 2rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem 0.4rem 0 0;
  user-select: none;
  outline: 0;
  border: 0;
  background: linear-gradient(0deg, #32364b 0%, #ffffff 25%);

  &:hover {
    background-color: #d7d9e2;
  }

  &:focus {
    box-shadow: inset 0px 4px 0px 0px $redBackgroundHover;
  }

  &.outro {
    box-shadow: inset 0px 4px 0px 0px #fff;
    background: linear-gradient(0deg, #32364b 0%, $redBackground 25%);
  }
}
