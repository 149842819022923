form.human-form {
  input[type='text'].classic,
  input[type='password'].classic,
  textarea.classic {
    font-size: 1.2rem;
    padding: 0.9rem 1.4rem;
  }

  .input-wrapper {
    margin-bottom: 1rem;
  }

  .btn.submit {
    margin-top: 0.5rem;
  }
}
