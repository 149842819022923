.appbar {
  width: 100%;
  background-color: #272b40;
  z-index: 200;
  user-select: none;
  grid-area: menubar;
  display: flex;
  align-items: center;

  .logo {
    width: 2rem;
    margin-left: 1rem;
    margin-right: 0.5rem;
  }

  .project-meta {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;

    &__title {
      color: #b0b5c9;
      font-size: 1.3rem;

      &.touched {
        font-weight: bold;
      }
    }

    &__last-saved {
      color: #b0b5c9;
      font-size: 1.2rem;
      margin-left: 0.4rem;
      opacity: 0.6;
    }
  }
}

@import './menubar/menubar.scss';
@import './authbar/authbar.scss';
