.checkbox-input-wrapper {
  label {
    height: 100%;
    display: inline-flex;
    cursor: pointer;
    align-items: center;

    input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark-box {
        background-color: #2196f3;
        border: 0.1rem solid #2196f3;
      }

      &:checked ~ .checkmark-box {
        .checkmark {
          display: block;
        }
      }

      // On focus - checked
      &:checked:focus ~ .checkmark-box {
        box-shadow: 0 0 0 0.1rem #296b8b;
      }

      // On focus - checked or not checked
      &:focus ~ .checkmark-box {
        box-shadow: 0 0 0 0.1rem #296b8b;
      }
    }

    span.label {
      color: #bcc2e0;
      font-size: 1.1rem;
      margin-left: 0.5rem;

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      &.error {
        color: $errorRed;
      }
    }

    .checkmark-box {
      display: inline-block;
      height: 1rem;
      width: 1rem;
      background-color: #494e6b;
      border: 1px solid #606583;
      border-radius: $inputRadius;
      transition: all 150ms ease;
      flex-shrink: 0;
      position: relative;

      // Default checkmark look
      .checkmark {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('../../../img/icons/checkbox-mark.svg');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 0.8rem;
      }
    }

    // Checkmark on hover when not selected
    &:hover .checkmark-box {
    }
  }
}
