button.smart {
  border: 0;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 0.7rem 2rem;
  position: relative;
  border-radius: 0.4rem;
  overflow: hidden;
  cursor: pointer;
  transition: all 150ms ease;

  &.primary {
    color: white;
    background: rgb(33, 157, 252);
    background: linear-gradient(0deg, rgba(33, 157, 252, 1) 0%, rgba(61, 170, 254, 1) 100%);
    box-shadow: 0px 3px 10px 0px rgba(23, 152, 252, 0.4);

    &:hover {
      background: linear-gradient(0deg, rgb(66, 173, 255) 0%, rgb(86, 182, 255) 100%);
    }
  }

  &.danger {
    color: white;
    background: rgb(252, 33, 33);
    background: linear-gradient(0deg, rgb(252, 33, 33) 0%, rgb(254, 61, 61) 100%);
    box-shadow: 0px 3px 10px 0px rgba(252, 23, 23, 0.4);

    &:hover {
      background: linear-gradient(0deg, rgb(255, 63, 63) 0%, rgb(255, 86, 86) 100%);
    }
  }

  &:disabled {
    cursor: inherit;
  }

  &.loading {
    color: transparent;

    .loader {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      animation: 150ms ease 0 1 fadeIn;
      animation-fill-mode: forwards;

      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 0.5;
        }
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 2rem;
      }
    }
  }

  &.disabled {
  }
}

@keyframes ldio-yl0iikb1teo-1 {
  0% {
    top: 18px;
    height: 64px;
  }
  50% {
    top: 30px;
    height: 40px;
  }
  100% {
    top: 30px;
    height: 40px;
  }
}
@keyframes ldio-yl0iikb1teo-2 {
  0% {
    top: 20.999999999999996px;
    height: 58.00000000000001px;
  }
  50% {
    top: 30px;
    height: 40px;
  }
  100% {
    top: 30px;
    height: 40px;
  }
}
@keyframes ldio-yl0iikb1teo-3 {
  0% {
    top: 24px;
    height: 52px;
  }
  50% {
    top: 30px;
    height: 40px;
  }
  100% {
    top: 30px;
    height: 40px;
  }
}
.ldio-yl0iikb1teo div {
  position: absolute;
  width: 15px;
}
.ldio-yl0iikb1teo div:nth-child(1) {
  left: 17.5px;
  background: #ffffff;
  animation: ldio-yl0iikb1teo-1 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.2s;
}
.ldio-yl0iikb1teo div:nth-child(2) {
  left: 42.5px;
  background: #ffffff;
  animation: ldio-yl0iikb1teo-2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.1s;
}
.ldio-yl0iikb1teo div:nth-child(3) {
  left: 67.5px;
  background: #ffffff;
  animation: ldio-yl0iikb1teo-3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: undefineds;
}

.loadingio-spinner-pulse-0f0use9xt5gt {
  width: 31px;
  height: 31px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-yl0iikb1teo {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.31);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-yl0iikb1teo div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
