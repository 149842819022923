.full-loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.9);

  .content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    img {
      display: block;
      margin: 0 auto 1.5rem auto;
      width: 10rem;
    }

    .title {
      display: block;
      color: #fff;
      text-transform: uppercase;
      opacity: 0.8;
      font-size: 1.4rem;
    }

    .subtitle {
      margin-top: 1rem;
      display: block;
      color: #fff;
      opacity: 0.7;
      font-size: 1.4rem;
    }
  }
}
