.playback-controls-wrapper {
  flex-grow: 1;

  .playback-controls {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    width: fit-content;
  }

  .btn.playback-control {
    width: 3.2rem;
    height: 2.2rem;
    background-size: 2rem 1.1rem;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 0.4rem;
    }

    &:focus {
      box-shadow: inset 0px 0px 2px 1px $accentBlue;
    }

    &.active {
      background-color: $accentBlue;
    }

    &.play {
      background-image: url('../../img/icons/playback-play.svg');
    }
    &.next {
      background-image: url('../../img/icons/playback-next.svg');
    }
    &.prev {
      background-image: url('../../img/icons/playback-prev.svg');
    }
    &.stop {
      background-color: $accentBlue;
      background-image: url('../../img/icons/playback-stop.svg');
    }

    &.loop-start-end {
      background-image: url('../../img/icons/playback-loop-start-end.svg');
      &.active {
        background-image: url('../../img/icons/playback-loop-start-end-white.svg');
        background-color: $accentBlue;
      }
    }
    &.loop {
      background-image: url('../../img/icons/playback-loop.svg');
      &.active {
        background-image: url('../../img/icons/playback-loop-white.svg');
        background-color: $accentBlue;
      }
    }
  }
}
