#casparcg-panel {
  flex-shrink: 0;

  .btn#casparcg-export {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1rem;
    position: relative;
    overflow: hidden;

    img {
      height: 2rem;
      margin-right: 1rem;
    }

    .loader {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #3d4059;
      opacity: 0;
      overflow: hidden;
      pointer-events: inherit;
      transition: all 150ms ease;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.loading {
      .loader {
        opacity: 1;
      }
    }
  }

  .output-directory {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    .path {
      flex-grow: 1;
      text-align: left;
      font-size: 1.1rem;
      color: #d4d9f0;
    }

    button {
      font-size: 1rem;
    }
  }
}
