.popup-page-layout {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 500;

  &.solid {
    background-color: #1c1f2d;
  }
}
