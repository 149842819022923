.classic-table {
  font-size: 1.2rem;
  width: 100%;
  border-collapse: collapse;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: 0px 0px 0px 1px #363a54;

  th,
  td {
    border: 1px solid #363a54;
    padding: 0.7rem 1rem;
  }

  th {
    background-color: #24273d;
    font-weight: 400;
    text-transform: uppercase;
  }

  tr {
  }
}
