input[type='text'].classic,
input[type='password'].classic,
textarea.classic {
  width: 100%;
  box-sizing: border-box;
  background-color: #24273d;
  border: 1px solid #363a54;
  color: #fff;
  font-size: 1.1rem;
  padding: 0.8rem 1.3rem;
  border-radius: 0.4rem;
  outline: 0;

  &:focus {
    box-shadow: 0px 0px 0px 1px $accentBlue;
  }

  &.error {
    border: 1px solid $redBackground;
  }
}

textarea {
  min-height: 5rem;
  resize: vertical;
}
