$layerControlsSectionWidth: 25rem;
$timelineScaleAnimation: width 100ms linear;

.composition-panel {
  z-index: 3;
  grid-area: bottom;
  height: 25rem;
  display: flex;
  flex-direction: column;
  border-top: 0.1rem solid $panelBorderColor;
  position: relative;
  user-select: none;
  background-color: #1c1f2d;

  .controls-and-timeline-wrapper {
    display: flex;
    flex-grow: 1;
    overflow: auto;

    // All on the Left side
    .controls-wrapper {
      background: #272b40;
      display: flex;
      flex-direction: column;

      @import './controls/header/controls-header';
      @import './controls/layer/lc-layer';

      ::-webkit-scrollbar {
        height: 0;
        width: 0;
      }
    }

    // All on the Right side
    .timeline-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;

      @import './timeline/frame-selector/timeline-frame-selector';
      @import './timeline/layer/timeline-layers';
    }
  }

  @import './keyframe-symbol';

  // Bottom controls
  @import './bottom-controls/bottom-controls';
}
