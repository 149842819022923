form.auth {
  .btn.submit {
    width: 100%;
  }
}

/* Define standard popup dimensions */
#login.popup {
  width: 50rem;

  .left {
    flex-basis: 55%;
  }

  .right {
    flex-basis: 45%;
    text-align: center;

    img {
      width: 14rem;
      margin-bottom: 3rem;
    }
  }
}

#register.popup {
  width: 50rem;

  .left {
    flex-basis: 45%;
  }

  .right {
    flex-basis: 55%;
  }
}

#forgotPassword.popup,
#resetPassword.popup,
#feedback.popup {
  width: 50rem;

  h1 {
    margin-top: 0;
  }
}
