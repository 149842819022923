.bottom-controls-wrapper {
  display: flex;
  border-top: 0.1rem solid $panelBorderColor;
  flex-grow: 0;
  height: 2rem;
  align-items: center;
  padding: 0.2rem 1rem;
  z-index: 13;
  background-color: $darkPanelColor;

  .horizontal-slider {
    width: 10rem;
    height: 1rem;
    border-radius: 0.4rem;
    background: #272b40;
  }
  .example-thumb {
    background: $accentBlue;

    width: 1rem;
    height: 1rem;

    border-radius: 0.4rem;
  }

  .signature {
    img {
      display: block;
    }
  }

  .central {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .feedback-btn {
      display: block;
      color: #b0b5c9;
      font-size: 1.2rem;
      cursor: pointer;
      position: relative;
      border: 0;
      background: transparent;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .timeline-zoom-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      padding: 0.2rem 0.5rem;
    }

    &__level {
      font-size: 1.1rem;
      padding: 0 0.7rem;
      color: #c3c7dd;
    }
  }
}
