.mini-table {
  font-size: 1.1rem;
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;

  th,
  td {
    border: 1px solid #363a54;
    padding: 0.3rem 1rem;
    cursor: pointer;
  }

  th {
    background-color: #24273d;
    font-weight: 400;
    text-transform: uppercase;
  }

  tr {
    &:hover {
      background-color: #333755;
    }
  }
}
