.controls-header {
  height: $frameSelectorHeight;
  background: $lightPanelColor;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
  flex-shrink: 0;
  z-index: 20;

  .current-frame {
    font-size: 3.2rem;
    color: #bcc2e0;
    margin-bottom: 0.8rem;
  }

  .end-frame {
    font-size: 1.2rem;
    color: #858cac;
    margin-bottom: 1.2rem;
    flex-grow: 1;
  }

  .btn.add-new-layer {
    margin-bottom: 1.2rem;
    height: 2.5rem;
    width: 2.5rem;
  }
}
