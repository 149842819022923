.layer-controls {
  overflow-y: scroll;
  flex-grow: 1;
  padding-bottom: 0.8rem;

  .lc-layer {
    padding: 0 1rem;
    box-sizing: border-box;
    width: $layerControlsSectionWidth;
    background-color: $lightPanelColor;
    flex-shrink: 0;
    z-index: 11;
    border-right: 0.1rem solid #32364b;

    &.selected {
      background: #333642;
    }

    @import './lc-layer-header';
    @import './lc-property';
    @import '../visibility-toggle';
  }
}
