#desktop-only {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #272b3f;
  z-index: 10000;

  @media #{$tablet} {
    display: none;
  }

  .content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    text-align: center;
    width: 100%;
    box-sizing: border-box;

    img {
      max-width: 100%;
      width: 40rem;
      margin-bottom: 3rem;
    }

    h1 {
      font-weight: normal;
      font-size: 2.5rem;
    }
  }
}
