.auth-layout {
  display: flex;
  align-items: center;
  padding: 0 1rem 1rem 1rem;

  & > .left {
    flex-basis: 50%;
    padding-right: 2rem;
  }
  & > .right {
    flex-basis: 50%;
    border-left: 0.1rem solid #363a53;
    padding-left: 2rem;
  }

  h1 {
    font-weight: 400;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    margin-top: 0;
  }

  button.as-link {
    color: #abacb4;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    text-align: left;

    &:hover {
      text-decoration: underline;
    }
  }
}
