.renderer-controls-section {
  background: $darkPanelColor;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;

  .zoom-help {
    flex-grow: 1;
    color: #bec3db;
    font-size: 1.2rem;
    margin-left: 0.5rem;
    opacity: 0.7;
  }

  .zoom-icon {
    height: 1.2rem;
  }

  .current-zoom {
    color: #bec3db;
    font-size: 1.3rem;
    margin-left: 1rem;
    margin-right: 0.5rem;
  }

  button {
    margin-left: 0.5rem;
    height: 100%;
  }
}
