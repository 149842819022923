#about.popup {
  .popup__content {
    img {
      width: 23rem;
      margin-bottom: 1rem;
    }

    .version {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 2rem;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;

        a {
          margin-left: 0.5rem;
        }

        span {
          background-color: $accentBlue;
          font-size: 1rem;
          padding: 0.2rem 0.5rem;
          color: white;
          border-radius: 0.4rem;
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
