.properties-section {
  margin-top: 1rem;

  &__label {
    text-transform: uppercase;
    font-size: 1.1rem;
    color: #bec3e0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #464a63;
  }
}

.properties-row {
  display: flex;
  margin: 1rem 0;
  width: 100%;

  & > div {
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }

  .dropdown-search {
    width: 100%;
  }
}

.property-number {
  display: flex;
  align-items: center;

  img {
    display: block;
    width: 1.8rem;
    max-height: 1.5rem;
    margin-right: 0.5rem;
  }

  input {
    display: block;
    box-sizing: border-box;
    background-color: #24273d;
    border: 1px solid #363a54;
    color: #e5e7f3;
    font-size: 1.1rem;
    border-radius: 0.4rem;
    outline: 0;
    padding: 0;

    width: 4rem;
    height: 2.8rem;
    text-align: center;

    &:disabled {
      color: #949cc4;
    }

    &:focus {
      box-shadow: 0px 0px 0px 1px $accentBlue;
    }
  }
}

.properties-radio-group {
  display: block;

  &__options {
    display: flex;
  }

  button {
    background-color: #3d4059;
    outline: 0;
    border: 0;
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    color: #c3c7dd;
    width: 2.8rem;
    height: 2.8rem;
    cursor: pointer;
    transition: all 100ms ease;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #4d506b;
    }

    &.selected {
      background-color: #545772;
      box-shadow: inset 0px 0px 0px 1px #2b79b0;
    }

    &:first-child {
      border-radius: 0.4rem 0 0 0.4rem;
    }

    &:last-child {
      border-radius: 0 0.4rem 0.4rem 0;
    }

    img {
      height: 1.1rem;
      max-width: 1.5rem;
    }
  }
}
