.tabs-layout {
  &.vertical {
    display: flex;
    align-items: stretch;

    .tabs-layout__tabs {
      flex-shrink: 0;
    }
    .tabs-layout__content {
      flex-grow: 1;
    }
  }

  &__tabs {
    border-right: 1px solid #30344c;

    .tab {
      padding: 1rem 4rem 1rem 2rem;
      color: #b7cbdb;
      cursor: pointer;
      transition: all 150ms ease;
      font-size: 1.2rem;

      &:not(:last-child) {
        border-bottom: 1px solid #30344c;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &.active {
        background-color: $accentBlue;
        color: white;
      }
    }
  }

  &__content {
    display: flex;
    align-items: stretch;

    & > form {
      display: flex;
      align-items: stretch;
      width: 100%;
    }
  }
}
