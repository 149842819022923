$frameSelectorHeight: 6rem;
$lightPanelColor: #272b40;
$darkPanelColor: #1c1f2d;
$panelBorderColor: #32364b;
$layerHeight: 2rem;

$inputRadius: 0.4rem;

$lcGroupHeight: 2rem;
$lcPropertyHeight: 1.8rem;

// Breaking points
$landscapePhone: 'only screen and (min-width : 576px)';
$tablet: 'only screen and (min-width : 768px)';
$desktop: 'only screen and (min-width : 992px)';
$largeDesktop: 'only screen and (min-width : 1200px)';

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  background-color: #1c1f2d;
  color: white;
  font-family: 'Exo 2', sans-serif;
  user-select: none;
}

input,
button {
  font-family: 'Exo 2', sans-serif;
}

@import './colors.scss';

.app-layout {
  display: none;

  @media #{$tablet} {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: grid;
    grid-template-columns: 0fr 1fr 0fr;
    grid-template-rows: 0fr 0fr 1fr 0fr 0fr;
    grid-template-areas:
      'warning warning warning'
      'menubar menubar menubar'
      'leftSidebar main rightSidebar'
      'leftSidebar playback rightSidebar'
      'bottom bottom bottom';
  }
}

@import '../components/desktop-only/desktop-only.scss';
@import '../components/tutorial/tutorial.scss';
@import '../components/components';
@import '../components/renderer-panel/renderer-panel';
@import '../components/composition-panel/composition-panel';
@import '../components/playback-and-actions-panel/playback-and-actions-panel';
@import '../components/notifications/notifications.scss';

.render {
  .layer {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .layer img {
    position: absolute;
    display: block;
  }

  .element.text {
  }

  .element.image-sequence {
    overflow: hidden;
  }

  .element.image-sequence,
  .element.image {
    img {
      position: static;
      width: 100%;
      height: 100%;
    }
  }

  .element.video {
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  .element.image-loader {
    background: rgba(0, 0, 0, 0.15);

    &.fit-width .image {
      width: 100%;
    }

    &.fit-height .image {
      height: 100%;
    }

    &.contain .image {
      max-width: 100%;
      max-height: 100%;
    }

    &.stretch .image {
      width: 100%;
      height: 100%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .icon {
      pointer-events: none;
      width: 3rem;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #181a2b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #373b55;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #424664;
}
