.appbar {
  &.hideDropdown > ul.dropdown-menubar > li:hover > ul {
    li {
      cursor: default;
    }
    display: none;
  }

  ul.dropdown-menubar.to-right li ul {
    left: 0;
    li ul {
      left: 100%;
    }
  }
  ul.dropdown-menubar.to-left li ul {
    right: 0;
    li ul {
      right: 100%;
    }
  }

  ul.dropdown-menubar {
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    flex-grow: 1;

    li.disabled {
      opacity: 0.4;

      &:hover {
        background-color: inherit;
      }
    }

    li {
      display: block;
      color: #b0b5c9;
      font-size: 1.2rem;
      padding: 0.7rem 1.2rem;
      position: relative;

      &:hover {
        background-color: #2e3346;
      }

      &:hover > ul {
        display: block;
      }

      ul {
        display: none;
        margin: 0;
        position: absolute;
        top: 100%;
        // left: 0;
        white-space: nowrap;
        background-color: #1c1f2d;
        min-width: 14rem;
        padding: 0.5rem 0;
        box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);

        a {
          text-decoration: none;
        }

        li {
          padding-left: 1.7rem;
          padding-right: 1.7rem;
          position: relative;

          &:hover > ul {
            display: block;
          }

          .label {
            display: flex;
            align-items: center;
            &__name {
              flex-grow: 1;
              margin-right: 1rem;
            }
            &__shortcut {
              opacity: 0.7;
              font-size: 1rem;
            }
          }

          ul {
            display: none;
            position: absolute;
            // left: 100%;
            top: 0;
            transform: translateY(-0.5rem);
          }
        }
      }
    }
  }
}
