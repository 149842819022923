.notifications {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1002;

  @keyframes slideInFromLeft {
    0% {
      right: 0;
      transform: translateX(100%);
      opacity: 1;
    }
    8%,
    92% {
      right: 2rem;
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      right: 2rem;
      transform: translateY(-1rem);
      opacity: 0;
    }
  }

  @keyframes timelineAnimation {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  .notification {
    position: relative;
    box-sizing: border-box;
    overflow: hidden;

    color: #fff;
    padding: 1rem 2rem 1.1rem 1rem;
    border-radius: $inputRadius;
    min-width: 30rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;

    animation-duration: 8s;
    animation-name: slideInFromLeft;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 0.5rem;
      background: rgba(0, 0, 0, 0.2);
      content: '';
      border-radius: 0.7rem;

      animation-timing-function: linear;
      animation-duration: 7.5s;
      animation-name: timelineAnimation;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    &--success {
      background: $greenBackground;
    }

    &--error {
      background: $redBackground;
    }

    &__icon {
      margin-right: 1rem;
      margin-top: -0.2rem;
      width: 1.8rem;
      opacity: 0.7;
    }

    &__message {
      font-size: 1.4rem;
      white-space: nowrap;
    }
  }
}
