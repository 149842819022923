.dropdown-search {
  position: relative;
  border-radius: $inputRadius;

  &.error {
    box-shadow: 0px 0px 0px 1px $errorRed;
  }

  &.open {
    .arrow {
      transform: rotate(180deg);
    }
  }

  .search-results__item {
    display: flex;
    align-items: stretch;
    background-color: #373b55;
    height: 3rem;
    border-radius: $inputRadius;
    overflow: hidden;
    cursor: pointer;

    &.highlighted {
      font-weight: bold;
    }

    &:hover {
      background-color: $btnDefaultHover;
    }

    .label {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      flex-grow: 1;

      span {
        color: #bec2de;
        font-size: 1.1rem;
      }
    }

    .arrow {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      margin-right: 1rem;

      img {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  .dropdown-search__rest {
    background-color: #1b1e30;
    padding: 0.7rem;
    position: absolute;
    width: 100%;
    top: 100%;
    box-sizing: border-box;
    z-index: 500;

    .search-box {
      margin-bottom: 0.7rem;
    }

    .search-results {
      border-radius: $inputRadius;
      overflow: hidden;
      overflow: auto;

      &__item {
        background-color: #272b42;
        height: 2.8rem;
        border-radius: 0;

        &:hover {
          background-color: #3d4059;
        }

        .label {
          span {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
