.auth-bar {
  height: 100%;
  display: flex;
  align-items: stretch;

  .user-dd {
    .user-brief {
      display: flex;
      align-items: center;
      position: relative;

      .user-name {
        margin-right: 2.6rem;
      }
      .user-image {
        width: 1.8rem;
        height: 1.8rem;
        background-color: $accentBlue;
        border-radius: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
      }
    }
  }

  .register-btn {
    display: block;
    color: #b0b5c9;
    font-size: 1.2rem;
    padding: 0.7rem 1.2rem;
    cursor: pointer;
    position: relative;
    border: 0;
    background: transparent;

    &:hover {
      text-decoration: underline;
    }
  }

  .login-btn {
    background-color: $accentBlue;
    border: 0;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 150ms ease;

    img {
      width: 1.2rem;
    }

    span {
      font-weight: 600;
      font-size: 1.2rem;
      margin-left: 0.7rem;
      color: white;
    }

    &:hover {
      background-color: $accentBlueHover;
    }
  }
}
