#alignment-panel {
  flex-shrink: 0;

  &__content {
    display: flex;
  }

  button {
    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }

  .separator {
    width: 0.1rem;
    height: 3rem;
    background: #464a63;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
