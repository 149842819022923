.keyframe-symbol {
  width: 0.7rem;
  height: 0.7rem;
  border: 0;
  padding: 0;
  margin: 0;
  background: #d4d05e;
  transform: rotate(45deg);

  &:focus {
    outline-width: 0;
    background: #f3f1d0;
  }

  &--empty {
    background: transparent;
    box-shadow: inset 0 0 0 0.2rem #7980a6;
  }
}
