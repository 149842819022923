.resizer {
  width: 100%;
  height: 0.2rem;
  cursor: n-resize;
  transition: all 200ms ease;
  position: absolute;
  left: 0;
  z-index: 99;

  &:hover {
    background-color: $accentBlue;
  }
}
