#resources {
  padding: 0.8rem 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  .panel-title {
    padding: 0 1rem;
    margin-bottom: 0.8rem;
  }

  #resources__content {
    overflow-y: auto;
  }

  .resource-section {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #bcc2e0;

    &__header {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      background-color: #24273d;
      color: #bcc2e0;
      border: 0;
      width: 100%;
      cursor: pointer;
      box-sizing: border-box;
      transition: all 150ms ease;

      &:hover {
        background-color: #20233a;
      }

      .arrow {
        width: 0.8rem;
        height: 0.8rem;
        background-image: url('../../img/icons/resources/arrow.svg');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        transition: all 150ms ease;

        &.open {
          transform: rotate(90deg);
        }
      }

      .title {
        margin-left: 0.5rem;
        font-size: 1.1rem;
        text-transform: uppercase;
        white-space: nowrap;
      }

      .number-of-items {
        margin-left: 0.5rem;
        font-size: 0.9rem;
        background-color: #747ba2;
        color: #ffffff;
        padding: 0.1rem 0.3rem;
        border-radius: 0.2rem;
        font-weight: bold;
      }

      .spacer {
        flex-grow: 1;
      }

      button {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 0.4rem;
        outline: 0;
        border: 0;
        background: transparent;

        &:hover {
          background-color: #3d4059;
        }
      }
    }

    &__content {
      list-style: none;
      margin: 0;

      & > .resource-item {
        display: flex;
        align-items: center;
        padding: 0.4rem 1rem;
        width: 100%;
        background: transparent;
        border: 0;
        outline: 0;
        color: #bcc2e0;
        font-size: 1.2rem;
        overflow: auto;

        &:last-child {
          margin-bottom: 0.5rem;
        }

        &.active {
          background-color: $accentBlue;
          color: white;
        }

        &.hoverable:hover,
        &.hoverable:focus {
          background: #3d4059;
        }

        &.clickable:hover {
          background: #3d4059;
          cursor: pointer;
        }

        & > img {
          height: 1.2rem;
          max-width: 1.2rem;
        }

        & > span {
          margin-left: 0.5rem;
        }
      }
    }
  }
}
