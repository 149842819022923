.cp-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5rem;
  z-index: 100;
}

.chrome-picker {
  font-family: 'Exo 2', sans-serif !important;
}
