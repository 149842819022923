.tutorial-wrapper {
  .tutorial-shade {
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .target {
    position: fixed;
    box-shadow: 0px 0px 0px 3px #2ba1fd;
    border-radius: 0.4rem;
    z-index: 10001;
  }

  .caption {
    position: absolute;
    background-color: #1b84d6;
    color: rgba(255, 255, 255, 0.9);
    border-radius: 0.4rem;
    min-width: 25rem;
    max-width: 40rem;
    z-index: 5;
    padding: 1.5rem 1.5rem;

    .content {
      font-size: 1.4rem;
    }

    .controls {
      display: flex;
      justify-content: space-between;
      margin-top: 1.5rem;
      align-items: center;

      .step {
        font-size: 1.3rem;
      }

      .next {
        background-color: #2ba1fd;
        padding: 0.8rem 1.2rem;
        font-size: 1.3rem;
        display: flex;
        border: 0;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: white;
        border-radius: 0.4rem;
        transition: all 150ms ease;

        &:hover {
          box-shadow: 0px 0px 0px 3px #49b0ff;
        }

        img {
          height: 1.3rem;
        }
      }
    }

    &.bottom {
      bottom: 0%;
      left: 50%;
      transform: translateX(-50%) translateY(100%);
      margin-bottom: -1rem;
    }

    &.top {
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-100%);
      margin-top: -1rem;
    }

    &.left {
      top: 50%;
      left: 0;
      transform: translateX(-100%) translateY(-50%);
      margin-left: -1rem;
    }

    &.right {
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      margin-left: 1rem;
    }
  }
}
