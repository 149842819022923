.keyframe-property-row {
  position: relative;
  height: $lcPropertyHeight;

  .keyframe-wrapper {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: -0.3rem;
  }

  .easing-menu-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 105;
  }

  .easing-menu {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #1c1f2d;
    border: 0.2rem solid #444964;
    border-radius: 0.4rem;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 100;
    box-sizing: border-box;
    overflow: hidden;

    &:focus {
      outline: 0.2rem solid #626888;
    }

    &.mini {
      width: 1rem;
      height: 1rem;
      li,
      li.selected {
        display: none;
      }
    }

    &.isOpen {
      height: 8rem;
      border: 0;
      z-index: 101;
      max-width: inherit !important;
      overflow: auto;

      li {
        display: block;
        padding: 0.2rem 0.7rem;

        &.selected {
          background-color: #353a52;
        }
      }
    }

    li {
      font-size: 1rem;
      color: #cfdbe7;
      padding: 0 0.7rem;
      cursor: pointer;
      height: 1.4rem;
      display: flex;
      align-items: center;
      display: none;
      white-space: nowrap;

      &.selected {
        display: block;

        &:hover {
          background-color: #444964;
        }
      }

      &:hover {
        background-color: #444964;
      }
    }
  }
}
