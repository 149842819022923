.search-box {
  position: relative;

  input[type='text'] {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: $inputRadius;
    outline: 0;
    padding-right: 6rem;
    background-color: #24273d;
    border: 1px solid #363a54;
    color: #fff;
    padding: 0.6rem 1.1rem;
    font-size: 1.2rem;
  }

  .icon {
    position: absolute;
    height: 100%;
    width: 3.5rem;
    top: 0;
    right: 0;
    border: 0;
    background-color: $accentBlue;
    background-image: url('../../../img/icons/search.svg');
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 0 $inputRadius $inputRadius 0;
    outline: 0;
  }

  &.focus {
    input[type='text'] {
      box-shadow: 0px 0px 0px 1px $accentBlue;
    }
  }
}
