.popup-wrapper {
  position: fixed;
  z-index: 1000;
}

.popup-shade {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 200ms ease;
  background-color: rgba(0, 0, 0, 0);
  animation: 200ms ease 100ms 1 fadeIn;
  animation-fill-mode: forwards;

  @keyframes fadeIn {
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  max-width: 100vw;
  min-width: 50rem;
  width: auto;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: $inputRadius;
  z-index: 100;
  transition: all 200ms ease;
  opacity: 0;
  animation: 200ms ease 100ms 1 fadeInToTop;
  animation-fill-mode: forwards;

  a {
    color: #abacb4;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @keyframes fadeInToTop {
    0% {
      opacity: 0;
      margin-top: 3rem;
    }
    100% {
      opacity: 1;
      margin-top: 0;
    }
  }

  &__header {
    background: #24273d;
    padding-left: 1.5rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #30344c;
    height: 3.3rem;

    &__title {
      color: #b7cbdb;
      flex-grow: 1;
      font-size: 1.2rem;
      font-weight: 400;
      margin: 0;
      padding: 0;
    }

    &__close {
      width: 3.8rem;
      height: 3.3rem;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: transparent;
      background-size: 1rem 1rem;
      border: 0;
      cursor: pointer;
      border-radius: $inputRadius;
      outline: 0;
      padding: 0;
      background-image: url('../../../img/icons/popup-x.svg');

      &:hover,
      &:focus {
        background-color: #30344c;
      }

      &:focus {
        box-shadow: inset 0px 0px 0px 1px #646781a5;
      }
    }
  }

  &__content {
    padding: 2rem 1.5rem;
    background: #272b40;
    overflow: auto;
    font-size: 1.3rem;
    color: #cedfec;
    position: relative;

    &.no-padding {
      padding: 0;
    }

    .status {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #272b40;
      z-index: 10;

      &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        padding: 0 3rem;

        .status-icon {
          max-width: 8rem;
          max-height: 8rem;
          margin-bottom: 1rem;
        }

        .status-title {
          font-size: 1.4rem;
        }
      }
    }
  }
}
