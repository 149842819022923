.lc-property-group {
  .header {
    display: flex;
    align-items: center;
    height: $lcGroupHeight;
    cursor: pointer;
    opacity: 0.8;
    width: fit-content;

    &:hover {
      opacity: 1;
    }

    .title {
      text-transform: uppercase;
      font-size: 1.1rem;
      margin-right: 0.5rem;
      color: #858caf;
    }

    .arrow {
      width: 0.8rem;
      height: 0.8rem;
      background-image: url('../../../../img/icons/layer-controls-arrow.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      transform: rotate(90deg);
      transition: transform 200ms ease;
      margin-bottom: -0.2rem;

      &.open {
        transform: rotate(0deg);
      }
    }
  }

  .items {
    & > .lc-property-group {
      padding-left: 1.5rem;
    }
  }
}

.lc-property {
  height: $lcPropertyHeight;
  display: flex;
  position: relative;
  padding-left: 1.5rem;
  align-items: center;
  font-size: 1.2rem;
  color: #bcc2e0;

  &::before {
    width: 0.5rem;
    height: 0.1rem;
    position: absolute;
    left: 0.5rem;
    top: 50%;
    content: '';
    background-color: #bcc2e0;
  }

  .name {
    flex-grow: 1;
  }

  .value {
    display: flex;
    align-items: baseline;

    .input {
      display: inline-block;
      background: transparent;
      color: #bcc2e0;
      padding: 0;
      margin: 0;
      border: 0;
      box-sizing: border-box;
      margin-right: 0.5rem;
      outline: 0;
      font-size: 1.2rem;
      text-align: right;
      width: 5rem;

      &:focus {
        color: #ecefff;
        background-color: #7980a6;
        border-bottom: 0.2rem solid $accentBlue;
        padding: 0.1rem 0.2rem;
        margin-right: 0.3rem;
      }
    }

    &__unit {
      font-size: 1.1rem;
    }
  }

  .keyframe-controls {
    margin-left: 1rem;
    margin-bottom: 0.3rem;
  }
}
